<template>
  <div id="manageUser" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Manage User</div>
        <v-btn class="theme-btn-even" @click.stop="addUser()">
          <span class="I-create"></span>
          <span>Add User</span>
        </v-btn>
      </div>
    </div>

    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-tabs color="white" dark slider-color="red">
          <v-tab>List</v-tab>
          <v-tab>Summary</v-tab>

          <v-tab-item>
            <div class="box-S4">
              <v-text-field
                class="hideMessage"
                v-model="search"
                label="Search"
                v-on:keyup.enter="SearchPassenger()"
              ></v-text-field>
              <div class="b-action">
                <div class="b-btn">
                  <v-btn @click="SearchPassenger()">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <!-- <v-data-table :headers="headers" :search="search" :items="userItem"> -->
            <v-data-table :headers="headers" :items="userItem">
              <template v-slot:item.u_imageurl="{ item }">
                <img class="B-display In-table" :src="item.u_imageurl" />
              </template>
              <template v-slot:item.action="{ item }">
                <div class="b-action">
                  <div class="b-btn">
                    <v-btn fab class="btn-edit" @click="editUser(item)">
                      <span class="I-edit"></span>
                    </v-btn>
                    <v-btn
                      fab
                      class="primary btn-delete"
                      @click="deleteItem(item)"
                    >
                      <span class="I-bin"></span>
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item>
            <div class="box-S4 flex-between-center">
              <div class="box-S3 noPadding">
                <v-text-field
                  class="hideMessage"
                  v-model="searchtotal"
                  append-icon="mdi-account-search"
                  label="Search"
                ></v-text-field>
              </div>
              <div class="box-S1 noPadding">
                <v-btn
                  class="theme-btn-even btn-report Bsize100P noMargin"
                  @click="onExport"
                >
                  <span class="I-report"></span>
                  <span class="T-size-18">Export To Excel</span>
                </v-btn>
              </div>
            </div>
            <v-data-table
              :headers="headerstotal"
              :items="report1"
              :search="searchtotal"
              @current-items="getFiltered"
            ></v-data-table>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>

    <!-- <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Delete Confirmation ?</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex>Delete {{ deleteItem.FirstName }} ?</v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn   @click="closeDialog">Cancel</v-btn>
            <v-btn   :loading="loading" @click="deleteToServer">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  <v-btn v-show="user.RoleId===2" color="red darken-1"  @click="closeDialog">Cancel</v-btn>-->
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { PdfLineJoin } from "@syncfusion/ej2-pdf-export";
import downloadexcel from "vue-json-excel";
import XLSX from "xlsx";

//Grid จำเป็น
import {
  GridPlugin,
  Page,
  Sort,
  Group,
  Toolbar,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";

export default {
  data: () => ({
    userdata: "",
    search: "",
    searchtotal: "",
    deleteItem: 0,
    dialogDelete: false,
    loading: false,
    userItem: [],

    datafilter: null,
    headers: [
      { value: "u_imageurl", text: "", sortable: false },
      { value: "u_staffcode", text: "Staff Code", sortable: false },
      { value: "u_firstname", text: "First Name", sortable: true },
      { value: "u_lastname", text: "Last Name", sortable: true },
      { value: "u_username", text: "Username", sortable: false },
      { value: "r_name", text: "Position", sortable: false },
      { value: "o_name", text: "Owner", sortable: false },
      { value: "c_name", text: "Company", sortable: false },
      { value: "action", text: "", sortable: false },
    ],
    headerstotal: [
      { value: "Company", text: "Company", sortable: false },
      { value: "Total", text: "Total", sortable: true },
      { value: "Administrator", text: "Administrator", sortable: true },
      { value: "Shore Coordinator", text: "Shore Coordinator", sortable: true },
      { value: "Passenger", text: "Passenger", sortable: true },
      {
        value: "Flight Operation Officer",
        text: "Flight Operation Officer",
        sortable: true,
      },
      { value: "Check-in Officer", text: "Check-in Officer", sortable: true },
      {
        value: "Alcohol-Test Officer",
        text: "Alcohol-Test Officer",
        sortable: true,
      },
      {
        value: "Transportation Team",
        text: "Transportation Team",
        sortable: true,
      },
      { value: "Engineer", text: "Engineer", sortable: true },
      { value: "Catering", text: "Catering", sortable: true },
    ],
    report1: null,
    toolbarOptions: ["ExcelExport"],

    dialog: false,
  }),
  computed: {},
  components: {},
  mounted: function () {
    this.renderUI();
    this.userdata = this.$CurrentUser.GetCurrentUser();
  },
  provide: {
    grid: [Page, Sort, Group, Toolbar, ExcelExport],
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    //ExportExcel
    toolbarClickReport1(args) {
      if (args.item.id === "GridReport1_excelexport") {
        // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.gridReport1.excelExport();
      }
    },

    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
    },
    async onDelete(item) {
      this.deleteItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteToServer(id) {
      this.loading = true;
      try {
        //  await feathersClientUOA.service("user").remove(this.deleteItem.Id);
        this.renderUI();
      } catch (err) {
        alert("ไม่สามารถต่อ server ได้" + err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    },

    async renderUI() {
      try {
        //User
        const q = {};
        if (this.userdata.r_id == 2) {
          q.o_id = this.userdata.o_id;
        }
        q.$limit = 100;

        let res = await feathersClientUOA
          .service("viewuser")
          .find({ query: q });
        this.userItem = res.data;
      } catch (err) {}

      try {
        const { user } = await feathersClientUOA.get("authentication");
      } catch (err) {}

      try {
        var role = await feathersClientUOA.service("role").find({});
        this.userStatus = role;

        var company = await feathersClientUOA.service("company").find({});
        this.userStatus = company;
      } catch (err) {
        alert("ไม่สามารถต่อ server ได้" + err);
      }
      try {
        var res = await await feathersClientUOA
          .service("userofcompany")
          .find({});
        this.report1 = res[0].data;
      } catch (err) {
        alert("ไม่สามารถต่อ server ได้" + err);
      }
    },
    getFiltered(e) {
      this.json_data = e;
      console.log(e);
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.json_data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    addUser() {
      // this.mode = "create";
      // // this.formModel = Object.assign({}, this.defaultValue);
      // this.dialog = true;
      this.$router.push({
        path: "/userprofile",
        query: { u_id: 0, title: "Add User", redirect: "/manageuser" },
      });
    },
    editUser(item) {
      this.$router.push({
        path: "/userprofile",
        query: { u_id: item.u_id, title: "Edit User", redirect: "/manageuser" },
      });
    },

    async SearchPassenger() {
      try {
        if (this.search != "") {
          //User
          const q = {};
          if (this.userdata.r_id == 2) {
            q.o_id = this.userdata.o_id;
          }
          q.u_firstname = { $like: this.search + "%" };
          q.$limit = 100;

          let res = await feathersClientUOA
            .service("viewuser")
            .find({ query: q });
          this.userItem = res.data;
        }
      } catch (err) {}
    },
  },
};
</script>
